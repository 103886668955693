<template>
  <div class="bei-an">
    <div class="hxjz">
      富强 民主 文明 和谐 自由 平等 公正 法治 爱国 敬业 诚信 友善
    </div>
    <div>
      <span>© 2022 - {{ new Date().getFullYear() }} {{ host }} {{ email }}</span>
      <el-link
        type="info"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        >浙ICP备17057726号-2</el-link
      >
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    host: {
      get() {
        return window.location.host;
      }
    },
    email: {
      get() {
        return 'qiuxs@qiuxs.com'
      }
    }
  },
};
</script>

<style lang="less">
.bei-an {
  color: @secondary-text;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;

  span {
    margin-right: 0.5rem;
  }

  a {
    vertical-align: unset;
  }

  .hxjz {
    font-size: 12px;
  }
}
</style>